export const sites = [
  {
    name: "WUPHF",
    image: "wuphf-screenshot.png",
    desc: "A full-stack NextJS application that replicates Twitter.",
    tags: ["NextJS", "Prisma", "Postgres"],
    src: "https://wuphfpack.vercel.app",
  },
  {
    name: "Commerce Bank Cubicle Reservations",
    image: "commerce.png",
    desc:
      "This team project was created for Commerce Bank in my Software Engineering class. A user can sign up and reserve cubicles for a timeframe. The backend and REST API was created using Spring Boot (Java) with a MySQL database, and a React frontend.",
    tags: ["Spring Boot", "React", "MySQL"],
    src: "https://commerce.charlesgraham.co/",
    github: "",
  },
  {
    name: "Pizza You",
    image: "pizzayou-screenshot.png",
    desc:
      "My server-side web programming project. A user can sign up and make orders. Manager accounts have access to an order statistics page, and can access and update each order's status.",
    tags: ["PHP", "MySQL", "jQuery", "LAMP"],
    src: "https://pizzayou.charlesgraham.co",
  },
  // {
  //   name: "Overthrow",
  //   desc:
  //     "A detailed look at the foreign policy of past presidents since the dawn of the 20th century. Based on a book of the same name by Stephen Kinzer.",
  //   tags: ["React", "Gatsby", "MongoDB"],
  //   src: "https://amazing-kowalevski-620b21.netlify.app/",
  // },
  {
    name: "Charles' Bakery",
    desc:
      "My front-end web programming project. My teacher allowed me to use React, so this was my first time trying it! Later I re-made the site using Gatsby and Snipcart.",
    tags: ["Snipcart", "React", "Gatsby"],
    src: "https://upbeat-mahavira-066207.netlify.app/",
  },
  // {
  //   name: "Word Guess Game",
  //   desc:
  //     "An early Javascript project. Given a scrambled word, guess the word. You have 3 hints available to you.",
  //   tags: ["Javascript", "PHP", "jQuery"],
  //   src: "http://cs.ucmo.edu/~cpg55850/WordGuessGame/index.php",
  // },
  // {
  //   name: "ChatCord",
  //   desc:
  //     "A personal project. I recently learned how to create a basic chat application using WebSockets by following a YouTube tutorial.",
  //   tags: ["WebSockets", "Express", "NodeJS"],
  //   src: "https://pure-ravine-12446.herokuapp.com/",
  // },
]

export const games = [
  {
    name: "Space Shooter 2D",
    desc:
      "A short 2D shoot 'em up game, created as my final project in Game Programming II.",
    tags: ["Unity", "C#"],
    src: "https://charliechip95.itch.io/space-shooter-2d",
  },
  {
    name: "Reggie Cube",
    desc:
      "A game about the Reggie cube, a disembodied cube that contains Reggie's soul.",
    tags: ["Unity", "C#"],
    src: "https://charliechip95.itch.io/reggie-cube",
  },
  {
    name: "The King's Book",
    desc:
      "A short 3rd-person adventure game inspired by Minecraft. Bring the book to the king to spread the knowledge.",
    tags: ["Unity", "C#"],
    src: "https://charliechip95.itch.io/the-kings-book",
  },
]
